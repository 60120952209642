
import FormInput from "@/components/lib/input/FormInput.vue";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

interface ItemBase {
  id: number;
  name: string;
}

@Component({
  components: {
    FormInput,
  },
})
export default class SelectProjectChecklist extends Vue {
  @Prop({ required: true }) items: (ItemBase & { checklists: ItemBase[] })[];
  @Prop() selected: { project: number; checklist: number } | null;
  @Prop({ default: "Чеклист" }) placeholder?: string;

  @Prop({ default: 25 }) labelLimit?: number;

  get labelText(): string {
    if (!this.selected) return this.placeholder;

    const p = this.items.find((p) => p.id === this.selected.project);
    if (!p) return this.placeholder;

    const c = p.checklists.find((c) => c.id === this.selected.checklist);
    if (!c) return this.placeholder;

    return `${c.name} (${p.name})`;
  }

  get labelTextLimited(): string {
    return this.labelText.length > this.labelLimit
      ? `${this.labelText.substring(0, this.labelLimit)}...`
      : this.labelText;
  }

  @Emit("on-select")
  onSelect(project: number, checklist: number) {
    return { project, checklist };
  }
}
