import writeXlsxFile, { Columns, Row, SheetData } from "write-excel-file";
import { CardModel } from "@/services/types/cards";
import { getCardsChecklists } from "@/services/cards/cardsChecklistsStore";

// type ExcelDataItem = {
//   project: string;
//   checklist: string;
//   manager: string;
//   auditor: string;
//   link: string;
//   rating: number;
//   startedAt: string;
//   estimatedAt: string;
// };

function getColumnsConfig(data: SheetData): Columns {
  const criterions = Array.from({ length: 99 }, () => ({ width: 20 }));

  return [
    // project
    { width: 30 },
    // checklist
    { width: 30 },
    // manager
    { width: 20 },
    // auditor
    { width: 20 },
    // link
    { width: 20 },
    // rating
    { width: 20 },
    // startedAt
    { width: 20 },
    // estimatedAt
    { width: 20 },
    // criterions
    ...criterions,
  ];
}

function getChecklistName(id: number): string {
  return getCardsChecklists().find((c) => c.id === id)?.name || id.toString();
}

function getFlatCriterionsList(card: CardModel) {
  return card.estimation.categories.map((cat) => cat.criterions).flat();
}

function buildExcelDataRowsFromCards(cards: CardModel[]): Row[] {
  const dateFormat = "dd.mm.yyyy hh:mm ";
  const ratingFormat = "0.00";

  return cards.map((c) => [
    // project
    { type: String, value: c.project.name },
    // checklist
    { type: String, value: getChecklistName(c.estimation.checklistId) },
    // manager
    { type: String, value: c.manager.name },
    // auditor
    { type: String, value: c.estimation.auditor.name },
    // link
    { type: String, value: c.link, wrap: false },
    // rating
    {
      type: Number,
      value: Number(c.estimation.rating),
      format: ratingFormat,
    },
    // startedAt
    {
      type: Date,
      value: new Date(c.startedAt),
      format: dateFormat,
    },
    // estimatedAt
    {
      type: Date,
      value: new Date(c.estimation.updatedAt),
      format: dateFormat,
    },
    // criterions
    ...getFlatCriterionsList(c).map((cr) => ({
      type: Number,
      value: Number(cr.rating),
      format: ratingFormat,
    })),
  ]);
}

function getExcelHeaderDataRow(cards: CardModel[]): Row {
  const sampleCriterions = cards.length && getFlatCriterionsList(cards[0]);

  const criterions: Row = sampleCriterions.map((cr) => ({
    value: cr.name,
  }));

  const row = [
    // project
    { value: "Проект" },
    // checklist
    { value: "Чеклист" },
    // manager
    { value: "Менеджер" },
    // auditor
    { value: "Аудитор" },
    // link
    { value: "Ссылка" },
    // rating
    { value: "Общая оценка" },
    // startedAt
    { value: "Дата карточки" },
    // estimatedAt
    { value: "Дата оценки" },
    // criterions
    ...criterions,
  ];

  row.forEach((c) => {
    c.fontWeight = "bold";
    c.wrap = true;
    c.alignVertical = "center";
  });
  return row;
}

export async function buildExcelFromCardsList(
  fileName: string,
  cards: CardModel[]
) {
  const data: SheetData = [
    getExcelHeaderDataRow(cards),
    ...buildExcelDataRowsFromCards(cards),
  ];

  try {
    await writeXlsxFile(data, {
      columns: getColumnsConfig(data),
      stickyRowsCount: 1,
      sheet: `Отчет`,

      fileName: `${fileName}.xlsx`,
    });
  } catch (e) {
    window.reportError(e);
    console.error(e);
  }
}
