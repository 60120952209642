
import CardsListGrid from "@/components/grids/cardsList/CardsListGrid.vue";
import CardsListGridFilter from "@/components/grids/cardsList/CardsListGridFilter.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    CardsListGrid,
    CardsListGridFilter,
  },
})
export default class CardsList extends Vue {}
