
import { Component, Vue } from "vue-property-decorator";
import * as utils from "@/services/utils";
import { getStore } from "@/store/getStore";
import { CardsActionTypes } from "@/store/modules/cards/actions/types";
import { getCardsChecklists } from "@/services/cards/cardsChecklistsStore";
import { CardModel } from "@/services/types/cards";

type RenderItem = {
  id: number;
  project: string;
  checklist: string;
  manager: string;
  link: string;
  auditor: string;
  rating: string;
  startedAt: string;
  estimatedAt: string;
};

@Component({
  watch: {
    "$store.getters.getCardsList": "updateItems",
  },
})
export default class CardsListGrid extends Vue {
  // currentPage = 1;
  // perPage = 1;

  items: RenderItem[] = [];
  fields = [
    {
      key: "project",
      label: "Проект",
      sortable: true,
    },
    {
      key: "checklist",
      label: "Чеклист",
      sortable: true,
    },
    {
      key: "manager",
      label: "Менеджер",
      sortable: true,
    },
    {
      key: "auditor",
      label: "Аудитор",
      sortable: true,
    },
    {
      key: "link",
      label: "Ссылка",
    },
    {
      key: "rating",
      label: "Оценка",
      sortable: true,
    },
    {
      key: "startedAt",
      label: "Дата карточки",
      sortable: true,
    },
    {
      key: "estimatedAt",
      label: "Дата оценки",
      sortable: true,
    },
  ];

  get isLoading() {
    return getStore().getters.getCardsIsLoading;
  }

  async mounted() {
    await getStore().dispatch(CardsActionTypes.SET_CARDS_LIST, null);
  }

  async updateItems() {
    const items: CardModel[] = JSON.parse(
      JSON.stringify(getStore().getters.getCardsList)
    );
    this.items = items.map((i) => {
      return {
        id: i.id,
        project: i.project.name,
        link: i.link,
        manager: i.manager.name,
        checklist:
          getCardsChecklists().find((c) => c.id === i.estimation.checklistId)
            ?.name || "",
        auditor: i.estimation.auditor.name,
        rating: i.estimation.rating,
        startedAt: i.startedAt,
        estimatedAt: i.estimation.updatedAt,
      } as RenderItem;
    });
  }

  formatLink(url: string) {
    return `${url.replace(/(^\w+:|^)\/\//, "").substring(0, 20)}...`;
  }

  formatDate(date: string) {
    return date ? utils.renderDateFromString(date) : "";
  }

  /***
   * percentage 0-1
   */
  perc2color(percentage: number, maxHue = 120, minHue = 0) {
    const hue = percentage * (maxHue - minHue) + minHue;
    return `hsl(${hue}, 100%, 50%)`;
  }
}
