
import { BaseEntityModel } from "@/services/types";
import FormInput from "@/components/lib/input/FormInput.vue";
import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component({
  components: {
    FormInput,
  },
})
export default class SelectMultiple extends Vue {
  @Prop() btnLabel?: string;
  @Prop() selectedItems!: BaseEntityModel[];
  @Prop() itemsList!: BaseEntityModel[];

  noDataText = "Нет данных";
  componentClass = "SelectProjectsMultiple";
  searchValue = "";

  get getFilteredItemList(): BaseEntityModel[] {
    if (this.searchValue.length >= 1) {
      return this.itemsList.filter(
        (item) =>
          item.name
            .trim()
            .toLowerCase()
            .indexOf(this.searchValue.trim().toLowerCase()) >= 0
      );
    } else {
      return this.itemsList;
    }
  }

  onSearchChange(search: string) {
    this.searchValue = search;
  }

  getLabel(model: BaseEntityModel) {
    return model.name;
  }

  @Emit("on-select")
  handleCheckboxChange(items: BaseEntityModel[]) {
    return items;
  }
}
